<script>
/* COMPOSANTS */
import AkFormSubmitted from "@components/general/AkFormSubmitted";

/* MIXINS */
/* SERVICES */

export default {
  components: {AkFormSubmitted},
  props: {
    title: {type: String, required: true},
    width: {type: String, default: '600px'},
    cancelLabel: {type: String, default: undefined},
    validateLabel: {type: String, default: undefined},
    autoHideOnValidate: {type: Boolean, default: false},
    autoHideOnCancel: {type: Boolean, default: true},
    noCancelLabel: {type: Boolean, default: false},
  },
  emits: ['show', 'hide', 'cancel', 'validate'],
  data() {
    return {
      display: false,
      submitted: false,
    }
  },
  computed: {
    buildCancelLabel() {
      return this.cancelLabel ? this.cancelLabel : this.$t('cancel');
    },
    buildValidateLabel() {
      return this.validateLabel ? this.validateLabel : this.$t('validate');
    },
  },
  mounted() {
  },
  methods: {
    show() {
      this.submitted = false;
      this.setDisplay(true);
      this.$emit('show');
    },
    hide() {
      this.setDisplay(false);
      this.$emit('hide');
    },
    cancel() {
      if (this.autoHideOnCancel) this.setDisplay(false);
      this.$emit('cancel');
    },
    validate() {
      this.submitted = true;
      if (this.autoHideOnValidate) this.setDisplay(false);
      this.$emit('validate');
    },
    setDisplay(value) {
      this.display = value;
    }
  },
}
</script>

<template>
  <Dialog
      v-model:visible="display"
      :header="title"
      :modal="true"
      :style="{width: width}"
      @hide="cancel"
  >
    <AkFormSubmitted
        :submitted="submitted"
        reference="form"
    >
      <slot/>
    </AkFormSubmitted>

    <template #footer>
      <span v-if="!noCancelLabel"
          class="btn btn-outline-primary btn-xs mr-1"
          @click="cancel"
      >
        {{ buildCancelLabel }}
      </span>
      <span
          class="btn btn-primary btn-xs"
          @click="validate"
      >
        {{ buildValidateLabel }}
      </span>
    </template>
  </Dialog>
</template>